import * as React from 'react'
import ReactLoading from 'react-loading'

import { Layout } from '../layouts'
import './index.sass'
import { useLocatedPage } from '../hooks/use-located-page'

const WorkInKSA = () => {
  useLocatedPage('workinksa/')

  return (
    <Layout pageTitle="Part-Time Staff On Demand">
      <div className="index-page">
        <ReactLoading
          type="bubbles"
          color="#7767E4"
          height="7%"
          width="7%"
          className="index-page loader"
        />
      </div>
    </Layout>
  )
}

export default WorkInKSA
